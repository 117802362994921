import {
  bootstrapApplication,
  createApplication,
} from '@angular/platform-browser';
import { LoadChatbot } from './app/features/load-chatbot/load-chatbot.component';
import { createCustomElement } from '@angular/elements';
import { UiChatBotSaComponent } from './app/features/chat-bot/components/ui-chat-bot-sa/ui-chat-bot-sa.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { importProvidersFrom } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
// import { HttpLoaderFactory } from './app/shared/utils/translate-loaders';
import { RouterModule, Routes, provideRouter } from '@angular/router';
import { BootstrapAppComponent } from './app/bootstrap-app.component';
import { PageNotFoundComponent } from './app/features/page-not-found/page-not-found.component';
import { CustomLoaderFactory } from './app/shared/utils/translate-loader';

const routes: Routes = [
  { path: '', component: PageNotFoundComponent, pathMatch: 'full' },
  {
    path: ':subdomain/:funnelSelector/:lang',
    component: LoadChatbot,
  },
  {
    path: ':subdomain/:lang',
    component: LoadChatbot,
  },
  // { path: ':subdomain/:funnelSelector', component: LoadChatbot },
  // { path: '**', component: PageNotFoundComponent },
];

bootstrapApplication(BootstrapAppComponent, {
  providers: [
    provideRouter(routes),
    importProvidersFrom([
      BrowserAnimationsModule,
      HttpClientModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: CustomLoaderFactory,
          deps: [HttpClient],
        },
      }),
    ]),
  ],
})
  .then((moduleRef) => {
    const injector = moduleRef.injector;
    const el2 = createCustomElement(UiChatBotSaComponent, {
      injector,
    });
    customElements.define('ui-chat-bot-sa-element', el2);
  })
  .catch((err) => console.error(err));

// // the build that i need to use only to generate the web component

// (async () => {
//   const app = await createApplication({
//     providers: [
//       importProvidersFrom([BrowserAnimationsModule, HttpClientModule]),
//     ],
//   });

//   const el = createCustomElement(UiChatBotSaComponent, {
//     injector: app.injector,
//   });

//   customElements.define('ui-chat-bot-sa-element', el);
// })();

// if (isWebComponentBuild) {
//   (async () => {
//     const app = await createApplication({
//       providers: [
//         importProvidersFrom([BrowserAnimationsModule, HttpClientModule]),
//       ],
//     });

//     const el = createCustomElement(UiChatBotSaComponent, {
//       injector: app.injector,
//     });

//     customElements.define('ui-chat-bot-sa-element', el);
//   })();
// } else {
//   bootstrapApplication(HomepageComponent, {
//     providers: [importProvidersFrom([BrowserAnimationsModule, HttpClientModule])],
//   })
//   .then((moduleRef) => {
//     const injector = moduleRef.injector;
//     const el2 = createCustomElement(UiChatBotSaComponent, {
//       injector,
//     });
//     customElements.define('ui-chat-bot-sa-element', el2);
//   })
//   .catch((err) => console.error(err));
// }
