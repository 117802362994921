<div
  style="
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  "
>
  <div style="font-weight: 700; font-size: 48px; text-align: center">
    ERROR 404<br />PAGE NOT FOUND
  </div>
</div>
