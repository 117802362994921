export default {
  choose_an_option: "Scegli un'opzione:",
  enter_text_here: 'Inserisci qui il testo',
  redirect_counter_message: 'Ti reindirizzeremo al nostro sito web tra {{counter}} secondi.',
  en: 'English',
  de: 'Deutsch',
  fr: 'Français',
  it: 'Italiano',
  whatsapp: 'WhatsApp',
  sms: 'SMS /iMessage',
  email: 'E-Mail',
};
