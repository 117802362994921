import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiHttpService {
  constructor(private http: HttpClient) {}
  private headers: { headers: HttpHeaders } = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  public get<T>(url: string) {
    return this.http.get<T>(url, this.headers);
  }

  public post<T>(url: string, data?: any) {
    return this.http.post<T>(url, data, this.headers);
  }
  public patch<T>(url: string, data?: any) {
    return this.http.patch<T>(url, data, this.headers);
  }

  public readJsonFileFromAssets<T>(filename: string): Observable<T> {
    return new Observable<T>((observer) => {
      fetch(`./assets/mock_api/${filename}`)
        .then((response) => response.json())
        .then((json) => {
          observer.next(json as T);
          observer.complete();
        })
        .catch((error) => observer.error(error));
    });
  }
}
