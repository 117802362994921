import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { LocalRoutes } from '../utils/route-helper';

@Injectable({
  providedIn: 'root',
})
export class IconRegistryService {
  private static iconsInitialized = false;

  chatBotIcons: Array<string> = [
    'rating-awesome',
    'rating-not-so-good',
    'rating-ok',
    'rating-really-bad',
    'rating-very-good',
    'icon-goodbye',
    'arrow-polygon',
    'emoji-letter',
    'construction-emoji',
    'fog-emoji',
  ];
  // const chatBotIcons = icons;

  logos: Array<string> = ['logo-hypt', 'logo-whatsapp', 'logo-sms'];
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {}
  public initialize(): void {
    if (!IconRegistryService.iconsInitialized) {
      // console.log('initialize');
      this.chatBotIcons.forEach((title) =>
        this.registerIcon(title, LocalRoutes.chat_bot_icons)
      );

      this.logos.forEach((title) =>
        this.registerIcon(title, LocalRoutes.logos)
      );

      IconRegistryService.iconsInitialized = true;
    }
  }

  private registerIcon(title: string, route: string) {
    this.matIconRegistry.addSvgIcon(
      title,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        route.concat(title).concat('.svg')
      )
    );
  }
}
