<div class="px-3 mt-2" class="user-input-form">
  <mat-form-field class="full-width" appearance="outline" style="width: 100%">
    <!-- <mat-label>{{ inputValue }}</mat-label> -->
    <textarea
      [placeholder]="placeholderText"
      matInput
      [(ngModel)]="inputValue"
      name="inputValueField"
      (ngModelChange)="onValueChange($event)"
      [rows]="5"
    ></textarea>
  </mat-form-field>
</div>

<!-- <ng-container *ngIf="stepContent && stepForm">
  <form [formGroup]="stepForm" class="px-3 mt-2" class="user-input-form">
    <ng-container *ngFor="let inputItem of stepContent">
      <mat-form-field
        [formGroup]="stepForm"
        class="full-width"
        appearance="outline"
      >
        <mat-label>{{ inputItem.text }}</mat-label>
        <input
          matInput
          [formControlName]="inputItem.control"
          *ngIf="inputItem.type === 'text'"
        />
        <textarea
          matInput
          [formControlName]="inputItem.control"
          *ngIf="inputItem.type === 'textarea'"
          rows="5"
        ></textarea>
      </mat-form-field>
    </ng-container>
  </form>
</ng-container> -->
