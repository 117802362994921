export default {
  choose_an_option: 'Choose an option:',
  enter_text_here: 'Enter text here',
  redirect_counter_message: "I'll redirect you in {{counter}} seconds to our website",
  en: 'English',
  de: 'Deutsch',
  fr: 'Français',
  it: 'Italiano',
  whatsapp: 'WhatsApp',
  sms: 'SMS /iMessage',
  email: 'E-Mail',
};
