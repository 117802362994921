import {
  trigger,
  state,
  animate,
  style,
  transition,
} from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { MESSAGE_DELAY } from 'src/app/shared/constants/global.constants';

@Component({
  selector: 'user-message-field',
  templateUrl: './user-message-field.component.html',
  styleUrls: ['./user-message-field.component.scss'],
  animations: [
    trigger('rotateInDownLeft', [
      state(
        'void',
        style({
          transform: 'rotate3d(0, 0, 1, -45deg)',
          transformOrigin: 'right bottom',
          opacity: 0,
        })
      ),
      transition(':enter', [animate(`${MESSAGE_DELAY}ms ease-out`)]),
    ]),
  ],
})
export class UserMessageFieldComponent implements OnInit {
  @Input() userMessage: string | undefined;

  positionState = 'absolute';

  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {
      this.positionState = 'relative';
    }, 400);
  }
}
