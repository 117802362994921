import { Component, Input, OnInit } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  keyframes,
} from '@angular/animations';

@Component({
  selector: 'agent-message-field',
  templateUrl: './agent-message-field.component.html',
  styleUrls: ['./agent-message-field.component.scss'],

  animations: [
    trigger('rotateInDownLeft', [
      state(
        'void',
        style({
          transform: 'rotate3d(0, 0, 1, 5deg) scale(.92)',

          transformOrigin: 'left bottom',
          opacity: 0,
        })
      ),
      transition(':enter', [
        animate(
          '200ms ease-out',
          keyframes([
            style({
              transform: 'rotate3d(0, 0, 1, 3deg) scale(.95)',
              opacity: 0,
              offset: 0.1,
            }), // 30% into the animation timeline
            style({
              transform: 'rotate3d(0, 0, 1, 2deg) scale(.97)',
              opacity: 0.3,
              offset: 0.5,
            }), // 50% into the animation timeline
            style({ transform: 'rotate(0) scale(1)', opacity: 1, offset: 1 }), // 100% completion
          ])
        ),
      ]),
    ]),
  ],
})
export class AgentMessageFieldComponent implements OnInit {
  @Input() message: string | undefined;
  opacityTransition: boolean = false;
  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {
      this.opacityTransition = true;
    }, 0);
  }
}
