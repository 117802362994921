<div class="user-input-form">
  <ng-container *ngFor="let input of inputs">
    <mat-form-field
      appearance="outline"
      style="margin-bottom: -1em !important; width: 100%"
    >
      <!-- <mat-label>{{ inputValue }}</mat-label> -->
      <input
        matInput
        [(ngModel)]="input.value"
        name="inputValueField"
        (ngModelChange)="onValueChange(input.type, $event)"
        [placeholder]="input.text[currentLang]"
      />
    </mat-form-field>
  </ng-container>
</div>
