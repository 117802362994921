import { CommonModule } from '@angular/common';
import { Component, ViewEncapsulation } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UiChatBotSaComponent } from './features/chat-bot/components/ui-chat-bot-sa/ui-chat-bot-sa.component';

@Component({
  selector: 'bootstrap-app',
  templateUrl: './bootstrap-app.component.html',
  styleUrls: ['./bootstrap-app.component.scss'],
  standalone: true,
  imports: [CommonModule, UiChatBotSaComponent, RouterModule],
  encapsulation: ViewEncapsulation.None,
})
export class BootstrapAppComponent {}
