import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'powered-by-hypt',
  templateUrl: './powered-by-hypt.component.html',
  styleUrls: ['./powered-by-hypt.component.scss'],
})
export class PoweredByHyptComponent implements OnInit {
  @Input() funnelLanguage: string | undefined;
  urlLink: string = 'https://join-hypt.com/en';
  poweredByHypt: SafeHtml;
  constructor(private sanitizer: DomSanitizer) {
    const svgContent = `<svg width="183" height="14" viewBox="0 0 183 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.84113 4.69531L5.7275 4.99361C5.68772 4.81463 5.61812 4.64228 5.51869 4.47656C5.42257 4.30753 5.27342 4.16998 5.07124 4.06392C4.87238 3.95455 4.6006 3.89986 4.2559 3.89986C3.78857 3.89986 3.38256 4.00758 3.03786 4.22301C2.69317 4.43513 2.49596 4.70526 2.44624 5.03338C2.39653 5.32173 2.46282 5.55208 2.64511 5.72443C2.8274 5.89678 3.13232 6.03764 3.55988 6.14702L4.65363 6.42543C5.30988 6.59446 5.77721 6.84801 6.05562 7.18608C6.33734 7.52083 6.43346 7.95668 6.34397 8.49361C6.27106 8.93111 6.08048 9.32221 5.77224 9.6669C5.464 10.0116 5.06959 10.2834 4.589 10.4822C4.10841 10.6811 3.57314 10.7805 2.98318 10.7805C2.19767 10.7805 1.57787 10.6115 1.1238 10.2734C0.66973 9.93206 0.427779 9.43821 0.397949 8.7919L1.57124 8.51349C1.60107 8.92116 1.74691 9.2294 2.00874 9.43821C2.2739 9.6437 2.64511 9.74645 3.12238 9.74645C3.66926 9.74645 4.12499 9.63045 4.48957 9.39844C4.85415 9.16312 5.0613 8.88139 5.11102 8.55327C5.16073 8.2848 5.1077 8.06108 4.95193 7.8821C4.79946 7.70313 4.53431 7.56889 4.15647 7.4794L2.9434 7.18111C2.26727 7.01207 1.79331 6.75355 1.52153 6.40554C1.25306 6.05753 1.16357 5.62003 1.25306 5.09304C1.32598 4.66217 1.51159 4.28101 1.80988 3.94957C2.11149 3.61813 2.48933 3.35795 2.9434 3.16903C3.40079 2.98011 3.89795 2.88565 4.43488 2.88565C5.19056 2.88565 5.75567 3.05137 6.13019 3.38281C6.50472 3.71094 6.7417 4.14844 6.84113 4.69531Z" fill="#333D47"/>
<path d="M10.9042 10.7805C10.2015 10.7805 9.61321 10.6132 9.13925 10.2784C8.66529 9.94366 8.33054 9.47467 8.13499 8.87145C7.94275 8.26823 7.91127 7.56558 8.04053 6.76349C8.16647 5.9813 8.41671 5.30019 8.79124 4.72017C9.16908 4.13684 9.63807 3.68608 10.1982 3.3679C10.7616 3.0464 11.3814 2.88565 12.0576 2.88565C12.7602 2.88565 13.3469 3.05469 13.8175 3.39276C14.2915 3.72751 14.6262 4.19815 14.8218 4.80469C15.0173 5.40791 15.0505 6.11387 14.9212 6.92259C14.7953 7.69815 14.5417 8.37595 14.1606 8.95597C13.7827 9.53267 13.3137 9.98177 12.7536 10.3033C12.1968 10.6214 11.5803 10.7805 10.9042 10.7805ZM10.9241 9.72656C11.461 9.72656 11.9267 9.58902 12.3211 9.31392C12.7188 9.03883 13.0403 8.6759 13.2856 8.22514C13.5341 7.77438 13.7015 7.28717 13.7877 6.76349C13.8672 6.26634 13.8639 5.80398 13.7777 5.37642C13.6916 4.94555 13.5076 4.5992 13.2259 4.33736C12.9442 4.07221 12.5481 3.93963 12.0377 3.93963C11.5008 3.93963 11.0318 4.07884 10.6307 4.35724C10.233 4.63565 9.9115 5.00189 9.66624 5.45597C9.42097 5.91004 9.25691 6.39891 9.17405 6.92259C9.0945 7.41974 9.09616 7.8821 9.17902 8.30966C9.2652 8.7339 9.44914 9.07694 9.73087 9.33878C10.0159 9.5973 10.4136 9.72656 10.9241 9.72656Z" fill="#333D47"/>
<path d="M18.9693 0.439631L17.279 10.6214H16.1057L17.796 0.439631H18.9693Z" fill="#333D47"/>
<path d="M24.7575 7.49929L25.5132 2.98509H26.6865L25.4138 10.6214H24.2405L24.4592 9.32884H24.3797C24.141 9.71662 23.8079 10.0464 23.3804 10.3182C22.9528 10.5866 22.4507 10.7209 21.874 10.7209C21.3967 10.7209 20.989 10.6165 20.651 10.4077C20.3162 10.1955 20.0776 9.87737 19.9351 9.45313C19.7959 9.02557 19.7793 8.48698 19.8853 7.83736L20.7007 2.98509H21.874L21.0785 7.75781C20.989 8.31463 21.0736 8.75876 21.3321 9.0902C21.5906 9.42164 21.9635 9.58736 22.4507 9.58736C22.7424 9.58736 23.0506 9.51278 23.3754 9.36364C23.7035 9.21449 23.9952 8.9858 24.2504 8.67756C24.5089 8.36932 24.678 7.97656 24.7575 7.49929Z" fill="#333D47"/>
<path d="M32.1565 2.98509L31.9974 3.9794H28.04L28.1991 2.98509H32.1565ZM29.6508 1.15554H30.8241L29.611 8.43395C29.558 8.76539 29.5663 9.01397 29.6359 9.17969C29.7088 9.34209 29.8182 9.45147 29.964 9.50781C30.1131 9.56084 30.2739 9.58736 30.4462 9.58736C30.5755 9.58736 30.6816 9.58073 30.7644 9.56747C30.8473 9.5509 30.9136 9.53764 30.9633 9.5277L31.0428 10.5817C30.9533 10.6115 30.8324 10.6413 30.6799 10.6712C30.5308 10.7043 30.3468 10.7209 30.1281 10.7209C29.7966 10.7209 29.4851 10.6496 29.1934 10.5071C28.9017 10.3646 28.678 10.1475 28.5222 9.85582C28.3665 9.56416 28.325 9.19626 28.3979 8.75213L29.6508 1.15554Z" fill="#333D47"/>
<path d="M32.6487 10.6214L33.9214 2.98509H35.0947L33.822 10.6214H32.6487ZM34.866 1.69247C34.6373 1.69247 34.4401 1.61458 34.2744 1.45881C34.112 1.30303 34.0308 1.11577 34.0308 0.897017C34.0308 0.678267 34.112 0.491004 34.2744 0.335228C34.4401 0.179451 34.6373 0.101562 34.866 0.101562C35.0947 0.101562 35.2902 0.179451 35.4526 0.335228C35.6184 0.491004 35.7012 0.678267 35.7012 0.897017C35.7012 1.11577 35.6184 1.30303 35.4526 1.45881C35.2902 1.61458 35.0947 1.69247 34.866 1.69247Z" fill="#333D47"/>
<path d="M39.1229 10.7805C38.4203 10.7805 37.832 10.6132 37.358 10.2784C36.884 9.94366 36.5493 9.47467 36.3537 8.87145C36.1615 8.26823 36.13 7.56558 36.2593 6.76349C36.3852 5.9813 36.6355 5.30019 37.01 4.72017C37.3878 4.13684 37.8568 3.68608 38.4169 3.3679C38.9804 3.0464 39.6002 2.88565 40.2763 2.88565C40.979 2.88565 41.5656 3.05469 42.0363 3.39276C42.5102 3.72751 42.845 4.19815 43.0405 4.80469C43.2361 5.40791 43.2692 6.11387 43.14 6.92259C43.014 7.69815 42.7605 8.37595 42.3793 8.95597C42.0015 9.53267 41.5325 9.98177 40.9723 10.3033C40.4155 10.6214 39.799 10.7805 39.1229 10.7805ZM39.1428 9.72656C39.6797 9.72656 40.1454 9.58902 40.5398 9.31392C40.9375 9.03883 41.259 8.6759 41.5043 8.22514C41.7529 7.77438 41.9203 7.28717 42.0064 6.76349C42.086 6.26634 42.0827 5.80398 41.9965 5.37642C41.9103 4.94555 41.7264 4.5992 41.4446 4.33736C41.1629 4.07221 40.7669 3.93963 40.2564 3.93963C39.7195 3.93963 39.2505 4.07884 38.8495 4.35724C38.4517 4.63565 38.1303 5.00189 37.885 5.45597C37.6397 5.91004 37.4757 6.39891 37.3928 6.92259C37.3133 7.41974 37.3149 7.8821 37.3978 8.30966C37.4839 8.7339 37.6679 9.07694 37.9496 9.33878C38.2347 9.5973 38.6324 9.72656 39.1428 9.72656Z" fill="#333D47"/>
<path d="M46.2534 6.0277L45.4977 10.6214H44.3244L45.5972 2.98509H46.7307L46.5318 4.17827H46.6313C46.8699 3.79048 47.193 3.47893 47.6007 3.24361C48.0084 3.00497 48.5006 2.88565 49.0773 2.88565C49.5877 2.88565 50.0186 2.99171 50.3699 3.20384C50.7245 3.41264 50.9748 3.73082 51.1206 4.15838C51.2698 4.58262 51.2913 5.11956 51.1852 5.76918L50.3699 10.6214H49.1966L49.9921 5.84872C50.0915 5.25213 50.0136 4.7848 49.7584 4.44673C49.5065 4.10867 49.1071 3.93963 48.5602 3.93963C48.1824 3.93963 47.8327 4.02083 47.5112 4.18324C47.193 4.34564 46.9229 4.58262 46.7009 4.89418C46.4821 5.20573 46.333 5.58357 46.2534 6.0277Z" fill="#333D47"/>
<path d="M55.9741 13.4851L57.7341 2.98509H58.8676L58.6687 4.19815H58.8079C58.914 4.06558 59.0598 3.89654 59.2454 3.69105C59.4343 3.48224 59.6879 3.29664 60.0061 3.13423C60.3276 2.96851 60.7369 2.88565 61.2341 2.88565C61.8771 2.88565 62.4156 3.0464 62.8498 3.3679C63.284 3.68939 63.5906 4.14512 63.7696 4.73509C63.9486 5.32505 63.9718 6.02107 63.8392 6.82315C63.7066 7.63187 63.4531 8.33286 63.0785 8.92614C62.704 9.5161 62.2466 9.97349 61.7064 10.2983C61.1661 10.6198 60.5778 10.7805 59.9415 10.7805C59.4509 10.7805 59.0714 10.6993 58.803 10.5369C58.5345 10.3712 58.3389 10.1839 58.2163 9.97514C58.0937 9.76302 57.9992 9.58736 57.9329 9.44815H57.8335L57.1574 13.4851H55.9741ZM58.2511 6.80327C58.1583 7.37997 58.1583 7.88873 58.2511 8.32955C58.3472 8.76705 58.5378 9.11009 58.8228 9.35867C59.1112 9.60393 59.4907 9.72656 59.9613 9.72656C60.4519 9.72656 60.8811 9.5973 61.249 9.33878C61.6202 9.07694 61.9251 8.72562 62.1638 8.2848C62.4057 7.84067 62.5731 7.34683 62.6659 6.80327C62.7521 6.26634 62.7487 5.78243 62.6559 5.35156C62.5631 4.91738 62.3742 4.57434 62.0892 4.32244C61.8041 4.06724 61.413 3.93963 60.9159 3.93963C60.4386 3.93963 60.016 4.06061 59.6481 4.30256C59.2835 4.54119 58.9819 4.87595 58.7433 5.30682C58.508 5.73438 58.3439 6.23319 58.2511 6.80327Z" fill="#333D47"/>
<path d="M68.1483 10.7805C67.4457 10.7805 66.8574 10.6132 66.3834 10.2784C65.9094 9.94366 65.5747 9.47467 65.3791 8.87145C65.1869 8.26823 65.1554 7.56558 65.2847 6.76349C65.4106 5.9813 65.6609 5.30019 66.0354 4.72017C66.4132 4.13684 66.8822 3.68608 67.4423 3.3679C68.0058 3.0464 68.6256 2.88565 69.3017 2.88565C70.0044 2.88565 70.591 3.05469 71.0617 3.39276C71.5356 3.72751 71.8704 4.19815 72.0659 4.80469C72.2615 5.40791 72.2946 6.11387 72.1654 6.92259C72.0394 7.69815 71.7858 8.37595 71.4047 8.95597C71.0269 9.53267 70.5579 9.98177 69.9977 10.3033C69.4409 10.6214 68.8244 10.7805 68.1483 10.7805ZM68.1682 9.72656C68.7051 9.72656 69.1708 9.58902 69.5652 9.31392C69.9629 9.03883 70.2844 8.6759 70.5297 8.22514C70.7783 7.77438 70.9457 7.28717 71.0318 6.76349C71.1114 6.26634 71.1081 5.80398 71.0219 5.37642C70.9357 4.94555 70.7518 4.5992 70.47 4.33736C70.1883 4.07221 69.7922 3.93963 69.2818 3.93963C68.7449 3.93963 68.2759 4.07884 67.8749 4.35724C67.4771 4.63565 67.1556 5.00189 66.9104 5.45597C66.6651 5.91004 66.501 6.39891 66.4182 6.92259C66.3386 7.41974 66.3403 7.8821 66.4232 8.30966C66.5093 8.7339 66.6933 9.07694 66.975 9.33878C67.26 9.5973 67.6578 9.72656 68.1682 9.72656Z" fill="#333D47"/>
<path d="M74.8202 10.6214L73.7662 2.98509H74.9992L75.6753 8.83168H75.7548L78.36 2.98509H79.6128L80.2492 8.81179H80.3287L82.9537 2.98509H84.1867L80.5872 10.6214H79.4338L78.7378 4.75497H78.6185L75.9736 10.6214H74.8202Z" fill="#333D47"/>
<path d="M87.3871 10.7805C86.6546 10.7805 86.0498 10.6181 85.5725 10.2933C85.0952 9.9652 84.7621 9.50781 84.5732 8.92117C84.3843 8.3312 84.3544 7.64512 84.4837 6.86293C84.613 6.08073 84.8698 5.39134 85.2543 4.79474C85.6388 4.19484 86.1177 3.72751 86.6911 3.39276C87.2645 3.05469 87.8942 2.88565 88.5803 2.88565C88.978 2.88565 89.3608 2.95194 89.7287 3.08452C90.0966 3.21709 90.4148 3.43253 90.6833 3.73082C90.9517 4.02581 91.139 4.4169 91.2451 4.90412C91.3511 5.39134 91.3445 5.99124 91.2252 6.70384L91.1456 7.20099H85.2593L85.4184 6.18679H90.1115C90.1878 5.75592 90.1679 5.37145 90.0519 5.03338C89.9392 4.69531 89.742 4.4285 89.4603 4.23295C89.1819 4.03741 88.8289 3.93963 88.4013 3.93963C87.9605 3.93963 87.5495 4.05895 87.1684 4.29759C86.7872 4.53291 86.469 4.8312 86.2138 5.19247C85.9586 5.55043 85.7995 5.91501 85.7365 6.28622L85.5973 7.16122C85.4979 7.79427 85.5128 8.29806 85.6421 8.67259C85.7747 9.04711 86.005 9.31724 86.3331 9.48295C86.6613 9.64536 87.0722 9.72656 87.5661 9.72656C87.881 9.72656 88.171 9.68348 88.4361 9.5973C88.7013 9.50781 88.9383 9.37524 89.1471 9.19957C89.3559 9.0206 89.5315 8.79853 89.674 8.53338L90.7479 8.85156C90.5656 9.23603 90.3088 9.5741 89.9773 9.86577C89.6492 10.1541 89.2647 10.3795 88.8239 10.5419C88.3864 10.701 87.9075 10.7805 87.3871 10.7805Z" fill="#333D47"/>
<path d="M92.3811 10.6214L93.6538 2.98509H94.7873L94.5885 4.13849H94.668C94.8669 3.76065 95.1701 3.45407 95.5778 3.21875C95.9855 2.98343 96.418 2.86577 96.8754 2.86577C96.9649 2.86577 97.0726 2.86742 97.1986 2.87074C97.3245 2.87074 97.4223 2.87571 97.4919 2.88565L97.293 4.07884C97.2532 4.06889 97.1637 4.05398 97.0245 4.03409C96.8853 4.01089 96.7362 3.99929 96.5771 3.99929C96.2059 3.99929 95.8628 4.07718 95.548 4.23295C95.2331 4.38542 94.9696 4.59754 94.7575 4.86932C94.5454 5.13778 94.4095 5.44437 94.3498 5.78906L93.5544 10.6214H92.3811Z" fill="#333D47"/>
<path d="M100.567 10.7805C99.8343 10.7805 99.2294 10.6181 98.7522 10.2933C98.2749 9.9652 97.9418 9.50781 97.7529 8.92117C97.564 8.3312 97.5341 7.64512 97.6634 6.86293C97.7927 6.08073 98.0495 5.39134 98.434 4.79474C98.8185 4.19484 99.2974 3.72751 99.8708 3.39276C100.444 3.05469 101.074 2.88565 101.76 2.88565C102.158 2.88565 102.541 2.95194 102.908 3.08452C103.276 3.21709 103.595 3.43253 103.863 3.73082C104.131 4.02581 104.319 4.4169 104.425 4.90412C104.531 5.39134 104.524 5.99124 104.405 6.70384L104.325 7.20099H98.439L98.5981 6.18679H103.291C103.367 5.75592 103.348 5.37145 103.232 5.03338C103.119 4.69531 102.922 4.4285 102.64 4.23295C102.362 4.03741 102.009 3.93963 101.581 3.93963C101.14 3.93963 100.729 4.05895 100.348 4.29759C99.9669 4.53291 99.6487 4.8312 99.3935 5.19247C99.1383 5.55043 98.9792 5.91501 98.9162 6.28622L98.777 7.16122C98.6776 7.79427 98.6925 8.29806 98.8218 8.67259C98.9544 9.04711 99.1847 9.31724 99.5128 9.48295C99.841 9.64536 100.252 9.72656 100.746 9.72656C101.061 9.72656 101.351 9.68348 101.616 9.5973C101.881 9.50781 102.118 9.37524 102.327 9.19957C102.536 9.0206 102.711 8.79853 102.854 8.53338L103.928 8.85156C103.745 9.23603 103.488 9.5741 103.157 9.86577C102.829 10.1541 102.444 10.3795 102.004 10.5419C101.566 10.701 101.087 10.7805 100.567 10.7805Z" fill="#333D47"/>
<path d="M108.434 10.7805C107.795 10.7805 107.258 10.6198 106.824 10.2983C106.389 9.97349 106.083 9.5161 105.904 8.92614C105.728 8.33286 105.707 7.63187 105.839 6.82315C105.975 6.02107 106.229 5.32505 106.6 4.73509C106.974 4.14512 107.433 3.68939 107.977 3.3679C108.521 3.0464 109.114 2.88565 109.757 2.88565C110.254 2.88565 110.633 2.96851 110.895 3.13423C111.16 3.29664 111.353 3.48224 111.472 3.69105C111.591 3.89654 111.682 4.06558 111.745 4.19815H111.845L112.471 0.439631H113.645L111.954 10.6214H110.821L111.02 9.44815H110.88C110.771 9.58736 110.619 9.76302 110.423 9.97514C110.227 10.1839 109.969 10.3712 109.647 10.5369C109.329 10.6993 108.925 10.7805 108.434 10.7805ZM108.723 9.72656C109.193 9.72656 109.614 9.60393 109.985 9.35867C110.36 9.11009 110.672 8.76705 110.92 8.32955C111.169 7.88873 111.341 7.37997 111.437 6.80327C111.533 6.23319 111.53 5.73438 111.427 5.30682C111.325 4.87595 111.127 4.54119 110.836 4.30256C110.544 4.06061 110.158 3.93963 109.677 3.93963C109.183 3.93963 108.754 4.06724 108.39 4.32244C108.025 4.57434 107.728 4.91738 107.5 5.35156C107.271 5.78243 107.112 6.26634 107.022 6.80327C106.933 7.34683 106.931 7.84067 107.017 8.2848C107.104 8.72562 107.286 9.07694 107.564 9.33878C107.846 9.5973 108.232 9.72656 108.723 9.72656Z" fill="#333D47"/>
<path d="M118.353 10.6214L120.043 0.439631H121.216L120.6 4.19815H120.699C120.805 4.06558 120.953 3.89654 121.142 3.69105C121.331 3.48224 121.584 3.29664 121.902 3.13423C122.221 2.96851 122.628 2.88565 123.125 2.88565C123.768 2.88565 124.307 3.0464 124.741 3.3679C125.179 3.68939 125.485 4.14512 125.661 4.73509C125.84 5.32505 125.863 6.02107 125.731 6.82315C125.598 7.63187 125.344 8.33286 124.97 8.92614C124.599 9.5161 124.141 9.97349 123.598 10.2983C123.057 10.6198 122.469 10.7805 121.833 10.7805C121.342 10.7805 120.963 10.6993 120.694 10.5369C120.429 10.3712 120.234 10.1839 120.108 9.97514C119.985 9.76302 119.891 9.58736 119.824 9.44815H119.685L119.486 10.6214H118.353ZM120.142 6.80327C120.05 7.37997 120.051 7.88873 120.147 8.32955C120.244 8.76705 120.434 9.11009 120.719 9.35867C121.004 9.60393 121.382 9.72656 121.853 9.72656C122.343 9.72656 122.774 9.5973 123.145 9.33878C123.516 9.07694 123.821 8.72562 124.06 8.2848C124.299 7.84067 124.464 7.34683 124.557 6.80327C124.643 6.26634 124.64 5.78243 124.547 5.35156C124.458 4.91738 124.269 4.57434 123.981 4.32244C123.695 4.06724 123.304 3.93963 122.807 3.93963C122.33 3.93963 121.907 4.06061 121.539 4.30256C121.175 4.54119 120.873 4.87595 120.635 5.30682C120.399 5.73438 120.235 6.23319 120.142 6.80327Z" fill="#333D47"/>
<path d="M126.904 13.4851C126.705 13.4851 126.529 13.4685 126.377 13.4354C126.228 13.4055 126.125 13.3757 126.069 13.3459L126.546 12.3118C126.818 12.3847 127.06 12.4063 127.272 12.3764C127.487 12.3499 127.691 12.2455 127.883 12.0632C128.079 11.8842 128.283 11.6025 128.495 11.218L128.823 10.6214L127.292 2.98509H128.515L129.608 9.0902H129.688L132.81 2.98509H134.133L129.449 11.7251C129.231 12.1328 128.99 12.4659 128.728 12.7244C128.467 12.983 128.183 13.1735 127.878 13.2962C127.573 13.4221 127.249 13.4851 126.904 13.4851Z" fill="#333D47"/>
<path d="M155.495 5.8816C155.488 6.18617 155.454 6.48946 155.395 6.788L154.573 11.0475H152.832L153.641 6.83033C153.678 6.6362 153.697 6.43877 153.697 6.24086C153.697 5.34892 153.223 4.84513 152.205 4.84513C151.6 4.81914 151.009 5.03506 150.554 5.44792C150.099 5.86078 149.816 6.43878 149.764 7.06157L148.984 11.0485H147.241L149.306 0.370946H151.049L150.326 4.12559C151.018 3.54598 151.889 3.24452 152.779 3.277C153.13 3.23062 153.486 3.26443 153.823 3.37603C154.16 3.48762 154.469 3.67428 154.728 3.92278C154.987 4.17129 155.19 4.47556 155.323 4.81406C155.455 5.15256 155.514 5.51701 155.495 5.8816Z" fill="#333D47"/>
<path d="M165.114 3.36372L159.984 11.7257C158.953 13.4095 158.144 13.9411 156.848 13.9411C156.141 13.9611 155.449 13.7216 154.896 13.2649L155.759 11.9559C156.093 12.2749 156.532 12.45 156.986 12.4453C157.572 12.4453 157.976 12.1583 158.449 11.3954L158.686 11.0217L156.931 3.36372H158.675L159.929 9.06226L163.372 3.36372H165.114Z" fill="#333D47"/>
<path d="M173.393 6.64451C173.421 7.22786 173.333 7.81093 173.135 8.35806C172.937 8.90518 172.633 9.40487 172.242 9.82656C171.85 10.2483 171.38 10.5831 170.859 10.8106C170.338 11.0381 169.777 11.1535 169.211 11.1497C168.718 11.1954 168.222 11.1048 167.774 10.8871C167.326 10.6694 166.942 10.3324 166.662 9.91085L165.907 13.841H164.163L166.185 3.36475H167.847L167.694 4.16998C168.366 3.57044 169.231 3.25226 170.119 3.27803C170.555 3.25211 170.99 3.32133 171.398 3.48116C171.806 3.64099 172.176 3.88783 172.485 4.20556C172.794 4.52329 173.035 4.90476 173.191 5.32503C173.348 5.7453 173.417 6.19492 173.393 6.64451ZM171.637 6.7271C171.655 6.46022 171.616 6.1925 171.522 5.94312C171.428 5.69374 171.282 5.46886 171.094 5.28458C170.907 5.1003 170.682 4.96118 170.436 4.87718C170.189 4.79317 169.928 4.76637 169.671 4.79867C169.316 4.80007 168.964 4.87728 168.639 5.02546C168.313 5.17363 168.021 5.38958 167.78 5.65978C167.539 5.92998 167.355 6.24861 167.239 6.5957C167.123 6.94278 167.078 7.31085 167.106 7.67685C167.088 7.94368 167.127 8.21138 167.221 8.46076C167.315 8.71015 167.46 8.93505 167.648 9.11935C167.836 9.30365 168.061 9.44279 168.307 9.5268C168.553 9.61081 168.814 9.6376 169.071 9.60527C169.427 9.60386 169.778 9.52666 170.104 9.37849C170.429 9.23033 170.722 9.01439 170.963 8.74421C171.203 8.47403 171.388 8.15542 171.504 7.80833C171.62 7.46124 171.665 7.09315 171.637 6.7271Z" fill="#333D47"/>
<path d="M176.726 8.47279C176.71 8.58257 176.701 8.69323 176.698 8.80417C176.686 8.92448 176.7 9.04614 176.741 9.15972C176.781 9.27331 176.846 9.37578 176.931 9.45922C177.016 9.54265 177.118 9.60481 177.23 9.64087C177.342 9.67694 177.46 9.68595 177.576 9.66721C177.923 9.66704 178.263 9.56188 178.552 9.36474L178.859 10.6892C178.313 11.0133 177.689 11.1728 177.059 11.1497C176.784 11.1805 176.505 11.1496 176.242 11.0591C175.979 10.9687 175.739 10.8208 175.536 10.6255C175.334 10.4302 175.174 10.1921 175.069 9.92752C174.964 9.66292 174.915 9.37801 174.926 9.0922C174.925 8.88913 174.944 8.68648 174.982 8.48724L175.693 4.86062H174.481L174.76 3.42153H175.972L176.307 1.66654H178.052L177.703 3.42153H179.71L179.432 4.86062H177.423L176.726 8.47279Z" fill="#333D47"/>
<path d="M179.946 10.0275C179.941 9.86101 179.969 9.69522 180.028 9.54021C180.087 9.38519 180.176 9.24417 180.29 9.12572C180.403 9.00726 180.539 8.91383 180.688 8.85111C180.838 8.78838 180.998 8.75766 181.159 8.76081C181.302 8.75642 181.445 8.78256 181.578 8.83759C181.711 8.89262 181.832 8.97536 181.932 9.08066C182.033 9.18595 182.112 9.31154 182.163 9.44958C182.215 9.58762 182.238 9.73515 182.232 9.88297C182.236 10.048 182.207 10.2121 182.149 10.3656C182.09 10.5191 182.002 10.659 181.89 10.777C181.779 10.895 181.645 10.9888 181.498 11.0528C181.35 11.1168 181.192 11.1497 181.032 11.1497C180.889 11.1532 180.746 11.1266 180.612 11.0714C180.479 11.0163 180.358 10.9338 180.256 10.8289C180.154 10.7239 180.074 10.5988 180.021 10.4611C179.968 10.3233 179.943 10.1758 179.946 10.0275Z" fill="#333D47"/>
</svg>`;
    this.poweredByHypt = this.sanitizer.bypassSecurityTrustHtml(svgContent);
  }
  ngOnInit(): void {
    if (this.funnelLanguage && this.funnelLanguage == 'de') {
      this.urlLink = 'https://join-hypt.com';
    }
  }
}
