<div class="px-3 mt-2" class="user-input-form">
  <mat-form-field class="full-width" appearance="outline" style="width: 100%">
    <textarea
      [placeholder]="placeholderText"
      matInput
      [(ngModel)]="inputValue"
      name="inputValueField"
      (ngModelChange)="onValueChange($event)"
      [rows]="5"
    ></textarea>
  </mat-form-field>
</div>
