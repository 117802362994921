import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ScrollService {
  private _triggerScrollToBottom = new Subject<void>();
  triggerScrollToBottom$ = this._triggerScrollToBottom.asObservable();

  constructor() {}

  scrollToBottom(): void {
    this._triggerScrollToBottom.next();
  }
}
