<div
  *ngIf="step"
  class="options-component"
  style="overflow: hidden"
  [@collapse]="animationState"
>
  <ng-content></ng-content>
  <div
    class="text-help option-text"
    *ngIf="options.length > 1 && step.type !== 'FEEDBACK'"
  >
    {{ "choose_an_option" | translate }}
  </div>
  <div
    style="display: flex"
    [ngStyle]="{
      'flex-direction': step.type === 'FEEDBACK' ? 'row' : 'column'
    }"
    class="all-options-wrapper"
  >
    <ng-container *ngFor="let option of options; let i = index">
      <div
        [@zoomIn]="{ value: '', params: { delay: i * 200 } }"
        class="option-button-wrapper"
        style="flex: 1"
        [ngStyle]="{ flex: option.button?.['width'] ? option.button?.['width'] : ' ' }"
        [ngClass]="option.button?.['style']"
      >
        <ng-container
          *ngIf="
            option.text &&
            option.text[currentLang] &&
            option.text[currentLang].trim() !== ''
          "
        >
          <button
            (click)="onClick(option)"
            mat-flat-button
            class="chat-step user-option-button full-width"
            style="display: flex; height: 60px; flex-grow: 1"
            [ngStyle]="{
            'justify-content': alignCenter ? 'center' : 'start',
            
          }"
          >
            <div
              style="display: flex; align-items: center"
              [ngStyle]="{ 'margin-left': alignCenter ? '0px' : '20px' }"
            >
              <ng-container *ngIf="option.icon">
                <mat-icon
                  [svgIcon]="option.icon"
                  class="icon-emoji"
                  style="margin-right: 15px"
                ></mat-icon>
              </ng-container>

              {{ option.text[currentLang] | translate }}
            </div>
          </button>
        </ng-container>
        <!-- (click)="onClick(option)" -->
        <ng-container *ngIf="step.type === 'GOODBYE'">
          <button
            mat-flat-button
            class="chat-step user-option-button full-width"
            style="display: flex; height: 60px; justify-content: center"
          >
            {{ "redirect_counter_message" | translate : { counter: counter } }}
          </button>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="step.type === 'RATING'">
      <div class="text-help option-text" style="text-align: center">
        {{
          step.privacy_policy.text_before_link.message[currentLang] | translate
        }}

        <a
          [href]="step.privacy_policy.link.message[currentLang]"
          target="_blank"
          class="text-help"
          >{{ step.privacy_policy.text.message[currentLang] | translate }}</a
        >
      </div>
    </ng-container>
  </div>
</div>
