import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiHttpService } from 'src/app/shared/services/api-http.service';
import { ChatFlow, Step, flowDesign } from './interfaces/chat-flow.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ChatBotFlowApiService {
  // chat flow steps that can be removed later on when we have a backend
  // private chatFlowStepsSubject = new BehaviorSubject<any[]>([]);
  // tracking of user steps && flow config for SA
  private chatFlowSubject = new BehaviorSubject<ChatFlow | null>(null);
  chatFlow$ = this.chatFlowSubject.asObservable();

  // chat flow steps that can be removed later on when we have a backend
  private userStepsSubject = new BehaviorSubject<Step[]>([]);
  userSteps$ = this.userStepsSubject.asObservable();
  private flowConfigSubject = new BehaviorSubject<flowDesign | null>(null);
  private flowConfigListSubject = new BehaviorSubject<flowDesign[] | null>(
    null
  );
  flowConfigList$ = this.flowConfigListSubject.asObservable();
  flowConfig$ = this.flowConfigSubject.asObservable();

  constructor(private apiHttp: ApiHttpService) {}

  fetchFlowConfig(): Observable<ChatFlow> {
    const filename = 'demoStepsFromApi.json';
    return this.apiHttp.readJsonFileFromAssets<ChatFlow>(filename);
  }
  // New method to fetch flow config from API
  fetchFlowConfigFromApi(
    subdomain: string,
    funnel_selector: string,
    params: { [key: string]: string },
    lang?: string
  ): Observable<ChatFlow> {
    // chatflow_version: "string",
    const { touchpoint, test, ...otherParams } = params;
    let data: any = {
      funnel_selector: funnel_selector,
      subdomain: subdomain,
      // language: lang,
      touchpoint: touchpoint || '',
      // is_testing: test,
      chatbot_query_params: { ...(otherParams || {}) },
    };
    if (lang) data.language = lang;
    // console.log('lang', lang);
    if (touchpoint) data.touchpoint = touchpoint;
    if (test) data.is_testing = test;

    // console.log('fetchFlowConfigFromApi', data);
    // `${environment.apiServers.apiEndpointFeedback}/v2/chatflow?funnel_selector=${funnel_selector}`,

    return this.apiHttp.post<ChatFlow>(
      `${environment.apiServers.apiEndpointFeedback}/v2/chatflow`,
      data
    );
  }
}
