import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChatBotFlowApiService } from '../chat-bot/chat-bot-flow-api.service';
import { ChatFlow } from '../chat-bot/interfaces/chat-flow.interface';
import { UiChatBotSaComponent } from '../chat-bot/components/ui-chat-bot-sa/ui-chat-bot-sa.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'load-chatbot',
  templateUrl: './load-chatbot.component.html',
  styleUrls: ['./load-chatbot.component.scss'],
  standalone: true,
  imports: [CommonModule, UiChatBotSaComponent],
  providers: [],
  encapsulation: ViewEncapsulation.None,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LoadChatbot implements OnInit {
  chatFlow: ChatFlow | null = null;
  funnelLanguage: string | undefined;
  constructor(
    private chatBotFlowApiService: ChatBotFlowApiService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    let subdomain: string;
    let funnel_selector: string;
    let lang: string;
    this.activatedRoute.params.subscribe((params) => {
      // Assuming the URL is structured as '/subdomain/funnelSelector'
      // subdomain = params['subdomain'];
      // funnel_selector = params['funnelSelector'] ?? '';
      subdomain = params['subdomain'];
      funnel_selector = params['funnelSelector'] ?? '';
      lang = params['lang'];
      // this.getFunnelConfig(subdomain, funnel_selector);
    });
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      // console.log('Query Params:', queryParams);
      // const lang = queryParams['lang'];
      if (lang) {
        // console.log('Language:', lang);
        this.funnelLanguage = lang;
      }
      this.chatBotFlowApiService
        .fetchFlowConfigFromApi(subdomain, funnel_selector, queryParams, lang)

        .subscribe({
          next: (data) => {
            this.chatFlow = data;
            if (!this.funnelLanguage) {
              this.funnelLanguage = data.language.current_language;
            }
          },
          error: (error) => {
            console.error('Error fetching flow config from API:', error);
          },
        });
    });
  }
}
