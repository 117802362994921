<ui-chat-bot-sa-element
  *ngIf="chatFlow && funnelLanguage"
  [flowDesign]="chatFlow.design!"
  [chatFlow]="chatFlow"
  [testMode]="false"
  [funnelLanguage]="funnelLanguage"
></ui-chat-bot-sa-element>
<!-- <ui-chat-bot-sa
  *ngIf="chatFlow"
  [flowDesign]="chatFlow.design!"
  [chatFlow]="chatFlow"
></ui-chat-bot-sa> -->
