<ng-container *ngIf="message">
  <!-- <div class="agent-message-wrapper"> -->
  <div class="agent-message-box" @rotateInDownLeft>
    <div
      class="text-block chat-text-box"
      [ngClass]="opacityTransition ? 'opacity-transition' : ''"
    >
      {{ message }}
    </div>
  </div>
  <!-- </div> -->
</ng-container>
