export default {
  choose_an_option: 'Choisir une option:',
  enter_text_here: 'Saisissez votre texte ici',
  redirect_counter_message: 'Je vous redirige vers notre site web dans {{counter}} secondes',
  en: 'English',
  de: 'Deutsch',
  fr: 'Français',
  it: 'Italiano',
  whatsapp: 'WhatsApp',
  sms: 'SMS /iMessage',
  email: 'E-Mail',
};
