import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { StepContent } from '../../interfaces/chat-flow.interface';

@Component({
  selector: 'user-input-area',
  templateUrl: './user-input-area.component.html',
  styleUrls: ['./user-input-area.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserInputAreaComponent implements OnInit {
  @Input() placeholderText: string = 'Enter text here';
  inputValue: string = '';
  @Output() feedbackTextArea = new EventEmitter<string>();
  constructor() {}

  ngOnInit(): void {}

  onValueChange(newValue: string): void {
    // this.inputValue = event.target.value;
    // console.log('newValue', newValue);
    this.inputValue = newValue;
    this.feedbackTextArea.emit(newValue);
  }
}
