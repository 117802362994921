import {
  Component,
  ElementRef,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  ChatFlow,
  flowDesign,
  Step,
} from '../../interfaces/chat-flow.interface';
import { ScrollService } from '../../scroll.service';
import { BotStylingService } from 'src/app/shared/services/bot-styling.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { Subject, takeUntil } from 'rxjs';
import { ChatBotFlowSaService } from './chat-bot-flow-sa.service';
import { IconRegistryService } from 'src/app/shared/services/icon-registry.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ui-chat-bot-sa',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './ui-chat-bot-sa.component.html',
  styleUrls: ['./ui-chat-bot-sa.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class UiChatBotSaComponent implements OnInit {
  private destroy$ = new Subject<void>();
  @Input() chatFlow: ChatFlow | undefined;
  @Input() flowDesign: flowDesign | undefined;
  @Input() testMode: boolean = false;
  @Input() funnelLanguage: string | undefined;
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;
  steps: Step[] | null = null;
  private shadowRoot!: ShadowRoot;

  constructor(
    private scrollService: ScrollService,
    private stylingService: BotStylingService,
    private chatBotFlowSaService: ChatBotFlowSaService,
    private iconRegistry: IconRegistryService,
    private translateService: TranslateService,
    private elRef: ElementRef
  ) {
    this.iconRegistry.initialize();
  }

  ngOnInit(): void {
    // Apply initial styles

    this.shadowRoot = this.elRef.nativeElement.shadowRoot;
    const linkElement = document.createElement('link');
    linkElement.rel = 'stylesheet';
    linkElement.href =
      'https://fonts.googleapis.com/icon?family=Material+Icons';
    this.shadowRoot.appendChild(linkElement);
    // this.setCSSVariables(); // Update CSS variables
    if (this.flowDesign) {
      this.stylingService.applyDynamicStyling(this.flowDesign, this.shadowRoot);
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log('chatFlow', this.chatFlow);

    this.translateService.onLangChange.subscribe((event) => {
      this.funnelLanguage = event.lang; // Access the new language
      console.log('funnelLanguage', this.funnelLanguage);
    });

    if (changes['flowDesign'] && this.flowDesign) {
      this.stylingService.applyDynamicStyling(this.flowDesign, this.shadowRoot);
      // this.setCSSVariables(); // Update CSS variables
    }

    if (changes['chatFlow'] && this.chatFlow && this.flowDesign) {
      this.chatBotFlowSaService.setFlowConfig(this.chatFlow);
      this.stylingService.applyDynamicStyling(this.flowDesign, this.shadowRoot);
      // this.prefetchAvatarImage(this.flowDesign.avatars.selected);
      // console.log(
      //   'this.flowDesign.avatars.selected',
      //   this.flowDesign.avatars.selected
      // );

      // this.setCSSVariables(); // Update CSS variables
      this.chatBotFlowSaService.userSteps$
        .pipe(takeUntil(this.destroy$))
        .subscribe((steps) => {
          this.steps = steps;
        });

      this.scrollService.triggerScrollToBottom$
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          setTimeout(() => {
            this.scrollToBottom();
          }, 100);
        });
    }
  }

  prefetchAvatarImage(url: string): void {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      if (this.flowDesign) {
        this.flowDesign.avatars.selected = url;
      }
    };
  }
  scrollToBottom() {
    const container = this.scrollContainer.nativeElement;
    container.style.scrollBehavior = 'smooth';
    container.scrollTop = container.scrollHeight;
  }

  onLanguageChanged(languageToSetTo: string): void {
    // let isInitialLoad = true;

    if (this.chatFlow) {
      this.chatFlow.language.api.data.language = languageToSetTo;
      this.chatBotFlowSaService
        .updateFeedbackApi(this.chatFlow.language.api)
        .subscribe((response) => {
          // console.log('response Lang update', response);
        });
    }
    // console.log('onLanguageChanged', languageToSetTo);

    this.funnelLanguage = languageToSetTo;
    this.translateService.use(languageToSetTo);
    // isInitialLoad = false;
  }
}
