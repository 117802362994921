export default {
  choose_an_option: 'Wähle eine Option:',
  enter_text_here: 'Text hier eingeben',
  redirect_counter_message: 'Ich leite dich in {{counter}} Sekunden auf unsere Webseite weiter',
  en: 'English',
  de: 'Deutsch',
  fr: 'Français',
  it: 'Italiano',
  whatsapp: 'WhatsApp',
  sms: 'SMS /iMessage',
  email: 'E-Mail',
};
