<div class="d-flex" style="display: flex">
  <!-- <button mat-stroked-button style="height: 45px" class="language-switcher">
    language-switcher works!
  </button> -->

  <ng-container
    *ngIf="
      selectedLanguage &&
      languageObject &&
      languageObject.available_languages.length > 1
    "
  >
    <button
      mat-stroked-button
      [matMenuTriggerFor]="menu"
      class="language-switcher"
      style="height: 45px"
    >
      {{ selectedLanguage | translate }}
      <mat-icon style="order: 1; margin: 0 -4px 0 38px"
        >arrow_drop_down</mat-icon
      >
    </button>
    <mat-menu
      #menu="matMenu"
      xPosition="before"
      backdropClass="lang-switch-panel"
    >
      <ng-container *ngFor="let lang of languageObject.available_languages">
        <button
          class="hypt-menu-button"
          mat-menu-item
          (click)="onSelectLanguage(lang)"
        >
          <span> {{ lang | translate }}</span>
        </button>
      </ng-container>
    </mat-menu>
  </ng-container>
</div>
