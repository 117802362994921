import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { contactFieldInput } from '../../interfaces/chat-flow.interface';

@Component({
  selector: 'user-input-contact-fields',
  templateUrl: './user-input-contact-fields.component.html',
  styleUrls: ['./user-input-contact-fields.component.scss'],
})
export class UserInputContactFieldsComponent implements OnInit {
  @Output() inputValue = new EventEmitter<{ key: string; value: string }>();
  @Input() inputs?: contactFieldInput[] = [];
  @Input() currentLang!: string;

  ngOnInit() {
    console.log('inputs', this.inputs);
  }
  onValueChange(key: string, newValue: string): void {
    // this.inputValue = event.target.value;
    // console.log('newValue', newValue);
    // console.log('type', key);
    if (this.inputs) {
      const inputElement = this.inputs.find((input) => input.type === key);
      if (inputElement) {
        inputElement.value = newValue;
        this.inputValue.emit({
          key: inputElement.type,
          value: inputElement.value,
        }); // console.log('inputElement', inputElement);
      }
    }
  }
}
