import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import en from '../translations/en';
import de from '../translations/de';
import fr from '../translations/fr';
import it from '../translations/it';
type Lang = 'en' | 'de' | 'fr' | 'it';
// Define a mapping from language codes to their respective translation objects
const TRANSLATIONS = {
  en: en,
  de: de,
  fr: fr,
  it: it,
  // Add other translations here
};

class CustomTranslateLoader implements TranslateLoader {
  getTranslation(lang: Lang): Observable<any> {
    // Return the translation object for the requested language
    const translation = TRANSLATIONS[lang];
    if (!translation) {
      console.warn(`Translation for language "${lang}" not found.`);
      return of({}); // Return an empty object if the translation is not found
    }
    return of(translation);
  }
}

// Use this function to provide the custom loader
export function CustomLoaderFactory() {
  return new CustomTranslateLoader();
}
