import { Injectable } from '@angular/core';
import { flowDesign } from 'src/app/features/chat-bot/interfaces/chat-flow.interface';

@Injectable({
  providedIn: 'root',
})
export class BotStylingService {
  applyStyles(
    selector: string,
    styles: Record<string, string>,
    shadowRoot: ShadowRoot | null = null
  ): void {
    const styleContent = this.generateStyleContent(selector, styles);
    this.appendStyleToHead(styleContent, shadowRoot);
  }

  private generateStyleContent(
    selector: string,
    styles: Record<string, string>
  ): string {
    let styleContent = `${selector} { `;
    Object.keys(styles).forEach((styleName) => {
      styleContent += `${styleName}: ${styles[styleName]} !important; `;
    });
    styleContent += `}`;
    return styleContent;
  }

  private appendStyleToHead(
    styleContent: string,
    shadowRoot: ShadowRoot | null
  ): void {
    const styleTag = document.createElement('style');
    styleTag.type = 'text/css';
    styleTag.innerHTML = styleContent;

    if (shadowRoot) {
      shadowRoot.appendChild(styleTag);
    } else {
      document.getElementsByTagName('head')[0].appendChild(styleTag);
    }
  }

  applyDynamicStyling(
    flowDesign: flowDesign,
    shadowRoot: ShadowRoot | null = null
  ): void {
    if (flowDesign) {
      this.applyStyles(
        'html',
        {
          'font-family': flowDesign.typography.font_family,
          '--mdc-typography-font-family': flowDesign.typography.font_family,
          '--mdc-typography-button-font-family':
            flowDesign.typography.font_family,
          '--mat-menu-item-label-text-font': flowDesign.typography.font_family,
        },
        shadowRoot
      );

      this.applyStyles(
        '.app-container-chatbot',
        {
          background: flowDesign.background_color,
        },
        shadowRoot
      );
      this.applyStyles(
        '.text-help',
        {
          color: flowDesign.help_text_color,
        },
        shadowRoot
      );
      this.applyStyles(
        '.mdc-checkbox',
        {
          color: flowDesign.typography.bot_text_color,
        },
        shadowRoot
      );
      this.applyStyles(
        'button.user-option-button',
        {
          'border-radius': flowDesign.button.border_radius + 'px',
        },
        shadowRoot
      );
      this.applyStyles(
        '.agent-message-box',
        {
          color: flowDesign.typography.bot_text_color,
        },
        shadowRoot
      );
      this.applyStyles(
        '.feedback-checkbox-option label',
        {
          color: flowDesign.typography.bot_text_color,
        },
        shadowRoot
      );
      this.applyStyles(
        '.user-message-box',
        {
          color: flowDesign.button.text_color,
          'background-color': flowDesign.button.background_color,
        },
        shadowRoot
      );

      this.applyStyles(
        '.mat-mdc-unelevated-button',
        {
          '--mdc-filled-button-container-color':
            flowDesign.button.background_color,
          '--mdc-filled-button-label-text-color': flowDesign.button.text_color,
        },
        shadowRoot
      );

      this.applyStyles(
        '.user-option-button.mat-mdc-unelevated-button.outline:not(:disabled)',
        {
          color: flowDesign.button.background_color,
          'background-color': flowDesign.button.text_color,
          border: `1px solid ${flowDesign.button.background_color}`,
        },
        shadowRoot
      );

      this.applyStyles('html', {
        // '--mdc-outlined-text-field-hover-outline-color':
        //   flowDesign.button.background_color,
        // '--mdc-outlined-text-field-focus-outline-color':
        //   flowDesign.button.background_color,
        '--mdc-outlined-text-field-focus-label-text-color':
          flowDesign.button.background_color,
        '--mdc-filled-text-field-focus-label-text-color':
          flowDesign.button.background_color,
        '--mdc-outlined-text-field-input-text-color':
          flowDesign.typography.bot_text_color,
        '--mdc-outlined-text-field-input-text-placeholder-color':
          'rgba(0, 0, 0, 0.38)',
      });
      const botTextColorWithOpacity =
        flowDesign.typography.bot_text_color + '33';
      this.applyStyles(
        ':host',
        {
          '--dot-color': flowDesign.typography.bot_text_color,
          '--dot-color-20': botTextColorWithOpacity,
        },
        shadowRoot
      );
    }
  }
}
// import { Injectable } from '@angular/core';
// import { flowDesign } from 'src/app/features/chat-bot/interfaces/chat-flow.interface';

// @Injectable({
//   providedIn: 'root',
// })
// export class BotStylingService {
//   private preloadedImageUrl: string | null = null;

//   applyStyles(
//     selector: string,
//     styles: Record<string, string>,
//     shadowRoot: ShadowRoot | null = null
//   ): void {
//     const styleContent = this.generateStyleContent(selector, styles);
//     this.appendStyleToHead(styleContent, shadowRoot);
//   }

//   private generateStyleContent(
//     selector: string,
//     styles: Record<string, string>
//   ): string {
//     let styleContent = `${selector} { `;
//     Object.keys(styles).forEach((styleName) => {
//       styleContent += `${styleName}: ${styles[styleName]} !important; `;
//     });
//     styleContent += `}`;
//     return styleContent;
//   }

//   private appendStyleToHead(
//     styleContent: string,
//     shadowRoot: ShadowRoot | null
//   ): void {
//     const styleTag = document.createElement('style');
//     styleTag.type = 'text/css';
//     styleTag.innerHTML = styleContent;

//     if (shadowRoot) {
//       shadowRoot.appendChild(styleTag);
//     } else {
//       document.getElementsByTagName('head')[0].appendChild(styleTag);
//     }
//   }

//   applyDynamicStyling(
//     flowDesign: flowDesign,
//     shadowRoot: ShadowRoot | null = null
//   ): void {
//     if (flowDesign) {
//       this.applyStyles(
//         'html',
//         {
//           'font-family': flowDesign.typography.font_family,
//           '--mdc-typography-font-family':
//             flowDesign.typography
//               .font_family /* Change the primary font family */,
//           '--mdc-typography-button-font-family':
//             flowDesign.typography
//               .font_family /* Change the button font family */,
//           '--mat-menu-item-label-text-font': flowDesign.typography.font_family,
//         },

//         null
//       );

//       this.applyStyles(
//         '.app-container-chatbot',
//         {
//           background: flowDesign.background_color,
//         },
//         shadowRoot
//       );
//       this.applyStyles(
//         '.text-help',
//         {
//           color: flowDesign.help_text_color,
//         },
//         shadowRoot
//       );
//       this.applyStyles(
//         '.mdc-checkbox',
//         {
//           color: flowDesign.typography.bot_text_color,
//         },
//         shadowRoot
//       );
//       // this.applyStyles('.border-help', {
//       //   border: `1px solid ${flowDesign.help_text_color}`,
//       // });

//       this.applyStyles(
//         'button.user-option-button',
//         {
//           'border-radius': flowDesign.button.border_radius + 'px',
//         },
//         shadowRoot
//       );
//       this.applyStyles(
//         '.agent-message-box',
//         {
//           color: flowDesign.typography.bot_text_color,
//         },
//         shadowRoot
//       );
//       this.applyStyles(
//         '.feedback-checkbox-option label',
//         {
//           color: flowDesign.typography.bot_text_color,
//         },
//         shadowRoot
//       );
//       this.applyStyles(
//         '.user-message-box',
//         {
//           color: flowDesign.button.text_color,
//           'background-color': flowDesign.button.background_color,
//         },
//         shadowRoot
//       );

//       this.applyStyles(
//         '.mat-mdc-unelevated-button',
//         {
//           '--mdc-filled-button-container-color':
//             flowDesign.button.background_color,
//           '--mdc-filled-button-label-text-color': flowDesign.button.text_color,
//         },
//         shadowRoot
//       );

//       this.applyStyles(
//         '.user-option-button.mat-mdc-unelevated-button.outline:not(:disabled)',
//         {
//           color: flowDesign.button.background_color,
//           'background-color': flowDesign.button.text_color,
//           border: `1px solid ${flowDesign.button.background_color}`,
//         },
//         shadowRoot
//       );

//       this.applyStyles(
//         'html',
//         {
//           '--mdc-outlined-text-field-hover-outline-color':
//             flowDesign.button.background_color,
//           '--mdc-outlined-text-field-focus-outline-color':
//             flowDesign.button.background_color,
//           '--mdc-outlined-text-field-focus-label-text-color':
//             flowDesign.button.background_color,
//           '--mdc-filled-text-field-focus-label-text-color':
//             flowDesign.button.background_color,
//           '--mdc-outlined-text-field-input-text-color':
//             flowDesign.typography.bot_text_color,
//         },
//         shadowRoot
//       );
//       const botTextColorWithOpacity =
//         flowDesign.typography.bot_text_color + '33';
//       this.applyStyles(
//         ':host',
//         {
//           '--dot-color': flowDesign.typography.bot_text_color,
//           '--dot-color-20': botTextColorWithOpacity,
//         },
//         shadowRoot
//       );
//       // this.applyStyles(':root', {
//       //   '--dot-color': flowDesign.typography.bot_text_color,
//       // });
//       // document.documentElement.style.setProperty(
//       //   '--dot-color',
//       //   flowDesign.typography.bot_text_color
//       // );
//     }
//   }
// }
